import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { color } from '../elements';
import { Stack } from '@mui/material';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';

const pages = [];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ isMobile }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const navigate = useNavigate();

  const handlePageClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
    handleCloseNavMenu();
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <AppBar sx={{ bgcolor: color.white }} position='sticky'>
      <Container disableGutters maxWidth='xl'>
        <Toolbar disableGutters sx={{ justifyContent: 'center' }}>
          <Stack
            onClick={() => {
              handlePageClick('/');
            }}
            sx={{ cursor: 'pointer' }}
            direction='row'
            alignItems='center'
          >
            <CommonImage
              sx={{ width: isMobile ? '40px' : '60px' }}
              src={sectionImages.KTLogo}
            ></CommonImage>
            <Typography
              fontSize={isMobile ? '1.2rem' : '1.6rem'}
              color={color.common_black}
            >
              GiGA sensing
            </Typography>
          </Stack>

          {/* <Box
            sx={{
              flexGrow: 1,
              // bgcolor: '#111',
              justifyContent: 'end',
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color={color.common_black}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => handlePageClick(page.path)}
                >
                  <Typography textAlign='center' color={color.common_black}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              justifyContent: 'end',
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handlePageClick(page.path)}
                sx={{ my: 2, color: color.common_black, display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
