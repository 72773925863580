import React from 'react';
import { Typography, Stack } from '@mui/material';
import { color } from '../elements';

const Footer = () => {
  return (
    <Stack
      py={10}
      px={5}
      bgcolor={color.common_black}
      color={color.white}
      letterSpacing={-0.8}
      spacing={2}
      textAlign={'left'}
    >
      <Typography variant='h5' fontWeight={'bold'} gutterBottom>
        KT비즈 솔루션
      </Typography>
      <Typography variant='body1'>대표자 : 이은아</Typography>
      <Typography variant='body1'>
        주소: 경기 안산시 상록구 중보로 47 404호 (이동, 고잔프라자)
      </Typography>
      <Typography variant='body1'>사업자번호 : 670-74-00187 </Typography>
      <Typography variant='body1'>
        통신판매업 신고번호: 제2023-경기안산-2071호
      </Typography>
      <Typography variant='body1'>대표번호: 1555-1750</Typography>
      <Typography variant='body2'>
        Copyright © KT 비즈솔루션(1555-1750). All Rights Reserved.
      </Typography>
    </Stack>
  );
};

export default Footer;
