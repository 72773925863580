import React from 'react';
import { sectionImages } from '../images';

import CommonImage from './CommonImage';

const Problem01 = ({ isMobile, isDesktop }) => {
  return (
    <CommonImage
      width='100%'
      height='inherit'
      sx={{ mt: -1 }}
      src={sectionImages.Problem1}
    />
  );
};

export default Problem01;
