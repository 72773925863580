export const color = {
  studio_back: '#25282f',
  white: '#ffffff',

  // Primary
  common_green_01: '#36BE00',
  common_green_02: '#A6F214',
  common_indigo: '#323842',
  common_indigo_02: '#41444D',
  common_black: '#26282E',

  // Hover
  common_green_03: '#78E04F',
  common_green_04: '#CDFF6C',
  // 새로추가된 그린
  common_green_05: '#a6f21426',

  // Red
  common_red_01: '#FF3B30',
  common_red_02: '#FFA5AD',

  // Blue
  common_blue: '#68E7EA',
  // 추가된
  common_blue02: '#ecf2fc',
  common_blue03: '#004cff',
  common_blue04: '#C5E3FE',

  // Gray
  common_gray_01: '#515C70',
  common_gray_02: '#9FA4AA',
  common_gray_03: '#C2C5C9',
  common_gray_04: '#D2D2D2',
  common_gray_05: '#ECECEC',
  common_gray_06: '#F5F5F5',

  // 새로 추가된 그레이
  common_gray_07: '#f2f2f2',
  common_gray_08: '#f6f6f6',
  common_gray_09: '#1d1d1d',
  common_gray_10: '#D9D9D9',
  common_gray_11: '#eee',
  common_gary_12: '#999CA1',
  common_gray_13: '#F9F9F9',
  common_gray_14: '#A7AAB3',

  // Pinkv
  common_pink_01: '#fc6ba6',

  // newColor
  common_new_white: '#F9F9FA',
  common_new_black_01: '#121316',
  common_new_black_02: '#41444D',
  common_new_black_03: '#000A10',
  common_new_black_04: '#020B26',
  common_new_blue_01: '#3565F2',
  common_new_blue_02: '#0583F2',
  common_new_blue_03: '#F5F7FE',
  common_new_blue_04: '#F7FBFF',
  common_new_blue_05: '#95AEF8',
  common_new_blue_06: '#E4F2FF',
  common_new_purple_01: '#96A0FF',
  common_new_gray_01: '#707584',
  common_new_gray_02: '#DEDFE2',
  common_new_gray_03: '#F9F9F9',
  common_new_gray_04: '#A7AAB3',
  common_new_gray_05: '#E5EAF1',

  common_new_red_01: '#E50914',
  common_new_red_02: '#EA4F57',
  common_new_red_03: '#FFEEEE',
  common_new_green_01: '#00A980',
};
