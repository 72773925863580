import Logo from './logo.png';

import KTLogo from './KT.png';

import Hero from './hero.jpg';
import HeroButton from './herobutton.png';
import Problem1 from './problem1.jpg';
import Problem2 from './problem2.jpg';
import Problem3 from './problem3.jpg';
import Problem4 from './problem4.jpg';
import Solution1 from './solution1.jpg';
import Solution2 from './solution2.jpg';
import Solution3 from './solution3.jpg';
import Faq from './faq.jpg';
import Cta from './cta.jpg';

export const sectionImages = {
  Logo,
  KTLogo,

  Hero,
  HeroButton,
  Problem1,
  Problem2,
  Problem3,
  Problem4,
  Solution1,
  Solution2,
  Solution3,
  Faq,
  Cta,
};
