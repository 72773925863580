import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';
import { scroller } from 'react-scroll';

const Hero = ({ isMobile, isDesktop }) => {
  const [fontSize, setFontSize] = useState('1.5rem');
  const [fontSize2, setFont2Size] = useState('1.5rem');
  const [fontSize3, setFont3Size] = useState('1.5rem');
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        setFontSize(`${buttonWidth * 0.1}px`); // 버튼 너비의 10%를 폰트 크기로 설정
        setFont2Size(`${buttonWidth * 0.2}px`); // 버튼 너비의 10%를 폰트 크기로 설정
        setFont3Size(`${buttonWidth * 0.09}px`); // 버튼 너비의 10%를 폰트 크기로 설정
      }
    };

    handleResize(); // 초기 설정
    window.addEventListener('resize', handleResize); // 윈도우 리사이즈 이벤트에 핸들러 추가

    return () => {
      window.removeEventListener('resize', handleResize); // 이벤트 리스너 정리
    };
  }, []);

  const handleButtonClick = () => {
    scroller.scrollTo('section02form', {
      duration: 800,
      delay: 1,
      smooth: 'easeInOutQuart',
    });
  };
  return (
    <Stack position={'relative'}>
      <CommonImage
        width="100%"
        height="inherit"
        // sx={{ mt: -1 }}
        src={sectionImages.Hero}
      />
      <Button
        ref={buttonRef}
        onClick={handleButtonClick}
        sx={{
          position: 'absolute',

          width: '30%',
          height: '10%',

          top: '75%', // 원하는 위치로 조정
          left: '50%', // 원하는 위치로 조정
          transform: 'translate(-50%, -50%)', // 중앙 정렬
          bgcolor: '#03CB5B',
          color: '#111',
          borderRadius: '40px',
          letterSpacing: '-0.8px',
          fontWeight: 'bold',
          fontSize: fontSize, // 동적으로 설정된 폰트 크기
          '&:hover': {
            bgcolor: '#029E43', // 호버 시 배경색 변경
          },
        }}
      >
        무료 상담 신청
      </Button>
      <Typography
        sx={{
          position: 'absolute',
          // bgcolor: '#fff',
          width: '50%',
          height: '10%',
          textAlign: 'center',
          top: '88%', // 원하는 위치로 조정
          left: '50%', // 원하는 위치로 조정
          transform: 'translate(-50%, -50%)', // 중앙 정렬
          // bgcolor: '#03CB5B',
          color: '#fff',
          borderRadius: '40px',
          letterSpacing: '-0.8px',
          fontWeight: 'bold',
          fontSize: fontSize3, // 동적으로 설정된 폰트 크기
          whiteSpace: 'pre-line',
          lineHeight: '1.2',
        }}
      >
        상담 및 가입문의 대표번호
        <br />
        <span style={{ color: '#03CB5B', fontSize: fontSize2 }}>
          1555-1750{' '}
        </span>
      </Typography>
    </Stack>

    // <Box
    //   component='div'
    //   sx={{
    //     width: '100%',
    //     height: 0, // 높이를 0으로 설정
    //     paddingTop: '104.6975%', // 이미지 비율에 대한 padding-top 값
    //     backgroundImage: `url(${sectionImages.Hero})`,
    //     backgroundSize: 'contain',
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    //     position: 'relative',
    //   }}
    // >
    //   <CommonImage
    //     width='100%'
    //     sx={{
    //       ml: 2,
    //       position: 'absolute', // 추가 이미지에 대한 위치 지정
    //       left: '38%', // 왼쪽 가장자리
    //       top: '73%', // 상위 Box의 상단으로부터 50% 위치
    //       transform: 'translateY(-50%)', // 세로 중앙 정렬
    //       width: '20%', // 너비 자동 조절
    //       // height: '40px', // 높이 설정 (필요에 따라 조정)
    //     }}
    //     src={sectionImages.HeroButton}
    //   />
    // </Box>
  );
};

export default Hero;
