import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

const CustomToolbar = ({ rows, columns }) => {
  const exportToExcel = () => {
    console.log({ rows });
    if (!rows || !rows.length) {
      console.error('No data to export');
      return;
    }

    const worksheetData = rows.map((row) => {
      const rowData = {};
      columns.forEach((column) => {
        rowData[column.field] = row[column.field];
      });
      return rowData;
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        startIcon={<GetAppIcon />}
        onClick={exportToExcel}
        color="primary"
        variant="contained"
      >
        엑셀로 내보내기
      </Button>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
