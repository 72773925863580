import React, { useEffect, useRef, useState } from 'react';
import { sectionImages } from '../images';

import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';
import { Box, Fade, Typography } from '@mui/material';

const Problem04 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  const [fontSize2, setFont2Size] = useState('1rem');
  const [fontSize3, setFont3Size] = useState('1.5rem');
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;

        setFont2Size(`${buttonWidth * 0.06}px`); // 버튼 너비의 10%를 폰트 크기로 설정
        setFont3Size(`${buttonWidth * 0.04}px`); // 버튼 너비의 10%를 폰트 크기로 설정
      }
    };

    handleResize(); // 초기 설정
    window.addEventListener('resize', handleResize); // 윈도우 리사이즈 이벤트에 핸들러 추가

    return () => {
      window.removeEventListener('resize', handleResize); // 이벤트 리스너 정리
    };
  }, []);
  const initObj = {
    name: '',
    phone: '',
    agreeToTerms: true,
  };
  return (
    <>
      <Box ref={sectionRef}>
        <CommonImage width="100%" src={sectionImages.Problem4} />
      </Box>
      <Box
        bgcolor={'#231e20'}
        sx={{ mt: -1 }}
        height={isMobile ? '150px' : '300px'}
        ref={buttonRef}
      >
        <Typography
          sx={{
            pt: 3,
            // bgcolor: '#fff',
            // width: '50%',
            // height: '10%',
            textAlign: 'center',

            // bgcolor: '#03CB5B',
            color: '#fff',
            // borderRadius: '40px',
            letterSpacing: '-0.8px',
            fontWeight: 'bold',
            fontSize: fontSize3, // 동적으로 설정된 폰트 크기
            whiteSpace: 'pre-line',
            lineHeight: '1.2',
          }}
        >
          상담 및 가입문의 대표번호
          <br />
          <span style={{ color: '#fd6f23', fontSize: fontSize2 }}>
            1555-1750{' '}
          </span>
        </Typography>
      </Box>
    </>
  );
};

export default Problem04;
