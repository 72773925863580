import React, { useState } from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Footer } from '../components';
import { color } from '../elements';
import { DataGrid } from '@mui/x-data-grid';

import CustomToolbar from '../components/CustomToolbar';
import { useQuery } from 'react-query';
import axios from 'axios';

const VIEW_MODE = {
  CALC: 'calc',
  FORM: 'form',
  SIMPLE_FORM: 'simpleForm',
};

const Admin = () => {
  const { isLoading, data } = useQuery('get-product', () => {
    return axios.get(
      'https://miwhiiekkk.execute-api.ap-northeast-2.amazonaws.com/prod/admin'
    );
  });
  console.log(data);

  const [isAdmin, setIsAdmin] = useState(false);
  const [key, setKey] = useState('');
  const matchKey = 'admin1234!!';
  const signInHandler = (e) => {
    setKey(e.target.value);
  };
  const validate = () => {
    if (key === matchKey) {
      setIsAdmin(true);
    }
  };
  if (isLoading) {
    return (
      <Container>
        <Stack height={'100vh'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <ResponsiveAppBar />
      {isAdmin && (
        <Stack height={'100vh'} my={2}>
          <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={data?.data[VIEW_MODE.FORM].rows.map(
                ({ createdAt, ...others }) => ({
                  ...others,
                  createdAt: new Date(createdAt).toLocaleString('ko-KR'),
                })
              )}
              columns={data?.data[VIEW_MODE.FORM].columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    rows={data?.data[VIEW_MODE.FORM].rows}
                    columns={data?.data[VIEW_MODE.FORM].columns}
                  />
                ),
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </Stack>
      )}
      {!isAdmin && (
        <Container disableGutters maxWidth="md">
          <Stack height={'100vh'} my={2}>
            <Typography
              textAlign={'center'}
              whiteSpace={'pre-line'}
              lineHeight={1.2}
              fontWeight={'bold'}
              fontFamily={'Pretendard'}
              letterSpacing={-0.8}
              my={7}
              mx={'auto'}
              fontSize={'2rem'}
            >
              KT GiGA sensing
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <TextField
                label="로그인"
                onChange={signInHandler}
                fullWidth
              ></TextField>
              <Button
                onClick={validate}
                sx={{ bgcolor: '#111', color: color.white }}
                variant="outlined"
              >
                SingIn
              </Button>
            </Stack>
          </Stack>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default Admin;
