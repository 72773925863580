import React, { useEffect } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { Element, scroller } from 'react-scroll';
import { sectionImages } from '../images';

import {
  Hero,
  Problem01,
  Problem02,
  Problem03,
  Problem04,
  Solution01,
  Solution02,
  Section02Form,
  Faq,
  Footer,
  Solution03,
} from '../components';
import ResponsiveAppBar from '../components/ResponsiveAppBar';

const sectionImagesList = Object.values(sectionImages);

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const hoverImgPreload = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };

  useEffect(() => {
    hoverImgPreload(sectionImagesList);
  }, []);
  return (
    <>
      {/* 메인섹션 */}
      <ResponsiveAppBar isMobile={isMobile} />
      <Container disableGutters maxWidth='xl'>
        {/* <Section01 isMobile={isMobile} />
        <Section01v2 isMobile={isMobile} /> */}
        <Hero isMobile={isMobile} />
        <Problem01 isMobile={isMobile} />
        <Problem02 isMobile={isMobile} />
        <Problem03 isMobile={isMobile} />
        <Problem04 isMobile={isMobile} />
        <Solution01 isMobile={isMobile} />
        <Solution02 isMobile={isMobile} />
        <Solution03 isMobile={isMobile} />
        {/* <Section01v4 isMobile={isMobile} /> */}
        {/* <Section02Form2 isMobile={isMobile} isDesktop={isDesktop} /> */}

        <Faq isMobile={isMobile} />
        <Element name='section02form'>
          <Section02Form isMobile={isMobile} isDesktop={isDesktop} />
        </Element>
      </Container>
      <Footer isMobile={isMobile} />
    </>
  );
};

export default Home;
