import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogAction,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { sectionImages } from '../images';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';
import { color } from '../elements';
function trackFacebookPixel(event) {
  if (typeof window.fbq === 'function') {
    window.fbq('track', event);
  }
}

const Section02Form = ({ isMobile, isDesktop }) => {
  const [fontSize, setFontSize] = useState('1.5rem');
  const [fontSize2, setFont2Size] = useState('3.5rem');
  const [fontSize3, setFont3Size] = useState('1.7rem');
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.offsetWidth;
        setFontSize(`${buttonWidth * 0.1}px`); // 버튼 너비의 10%를 폰트 크기로 설정
        setFont2Size(`${buttonWidth * 0.15}px`); // 버튼 너비의 10%를 폰트 크기로 설정
        setFont3Size(`${buttonWidth * 0.08}px`); // 버튼 너비의 10%를 폰트 크기로 설정
      }
    };

    handleResize(); // 초기 설정
    window.addEventListener('resize', handleResize); // 윈도우 리사이즈 이벤트에 핸들러 추가

    return () => {
      window.removeEventListener('resize', handleResize); // 이벤트 리스너 정리
    };
  }, []);
  const initObj = {
    name: '',
    phone: '',
    agreeToTerms: true,
  };
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  const postData = useMutation((data) =>
    axios.post(
      'https://miwhiiekkk.execute-api.ap-northeast-2.amazonaws.com/prod/form',
      data
    )
  );
  const [formData, setFormData] = useState(initObj);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const namePattern = /^[가-힣]{2,}$/; // 한글 이름 최소 2글자
    const phonePattern = /^01[0|1|6|7|8|9]\d{3,4}\d{4}$/; // 하이픈 없이

    const { name, phone, agreeToTerms } = formData;

    if (!name) {
      alert('이름을 입력해주세요!');
      return;
    }

    if (!namePattern.test(name)) {
      alert('유효한 이름을 입력해주세요! (한글 이름 최소 2글자)');
      return;
    }

    if (!phone) {
      alert('연락처를 입력해주세요!');
      return;
    }

    if (!phonePattern.test(phone)) {
      alert('유효한 연락처를 입력해주세요! (예: 01012345678)');
      return;
    }
    if (!agreeToTerms) {
      alert('개인정보 보호 동의 부탁드립니다.');
      return;
    }

    postData.mutate(formData);
    setFormData(initObj);
    alert('상담신청이 완료되었습니다!');
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (event) => {
    event.stopPropagation();
    setIsDialogOpen(false);
  };
  return (
    <Box id="section02form" sx={{ my: 10 }}>
      <Stack
        py={1}
        maxWidth={900}
        margin={'auto'}
        alignItems={'center'}
        spacing={2}
      >
        <Typography
          fontWeight={'bold'}
          fontSize={isMobile ? '1.3rem' : '2rem'}
          color={'#03CB5B'}
        >
          지금 KT GiGA sensing 만나보세요!
        </Typography>
        <Stack direction={'row'} alignItems={'center'} width={'100%'}>
          <Typography
            textAlign={'center'}
            width={70}
            color={color.common_black}
            fontSize={'1rem'}
            fontWeight={'bold'}
          >
            이름
          </Typography>
          <TextField
            placeholder={'이름'}
            fullWidth
            onChange={handleChange}
            name={'name'}
            value={formData.name}
            sx={{
              // mb: isMobile ? 0.5 : 1,
              // background: '#fff',
              '& .MuiOutlinedInput-root': {
                // height: isMobile ? '20px' : '50px', // Adjust the height based on the screen size
                '& fieldset': {
                  border: '1px solid #eee', // Removes the border
                },
                '&:hover fieldset': {
                  border: '1px solid #03CB5B', // Ensures the border remains removed on hover
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #03CB5B', // Ensures the border remains removed on focus
                },
              },

              // '& .MuiInputBase-input::placeholder': {
              //   // Targeting the placeholder
              //   color: 'darkgrey', // Setting placeholder text color
              //   fontSize: isMobile ? '0.7rem' : '1.2rem', // Setting font size of the placeholder
              //   fontWeight: 'bold',
              //   opacity: 1, // Ensures that the color is not reduced by default styles
              //   letterSpacing: -0.8,
              // },
            }}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'} width={'100%'}>
          <Typography
            textAlign={'center'}
            width={70}
            color={color.common_black}
            fontWeight={'bold'}
            fontSize={'1rem'}
          >
            연락처
          </Typography>
          <TextField
            fullWidth
            onChange={handleChange}
            placeholder={'연락처'}
            name={'phone'}
            value={formData.phone}
            sx={{
              // mb: isMobile ? 0.5 : 1,
              // background: '#fff',
              '& .MuiOutlinedInput-root': {
                // height: isMobile ? '20px' : '50px', // Adjust the height based on the screen size
                '& fieldset': {
                  border: '1px solid #eee', // Removes the border
                },
                '&:hover fieldset': {
                  border: '1px solid #03CB5B', // Ensures the border remains removed on hover
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #03CB5B', // Ensures the border remains removed on focus
                },
              },

              // '& .MuiInputBase-input::placeholder': {
              //   // Targeting the placeholder
              //   color: 'darkgrey', // Setting placeholder text color
              //   fontSize: isMobile ? '0.7rem' : '1.2rem', // Setting font size of the placeholder
              //   fontWeight: 'bold',
              //   opacity: 1, // Ensures that the color is not reduced by default styles
              //   letterSpacing: -0.8,
              // },
            }}
          />
        </Stack>

        <Stack alignItems={'center'} direction={'row'}>
          <FormControlLabel
            control={
              <Checkbox
                name="agreeToTerms"
                checked={formData.agreeToTerms}
                onChange={handleChange}
                sx={{
                  '& .MuiSvgIcon-root': {
                    width: isMobile ? 32 : 40,
                    height: isMobile ? 32 : 40,
                  },
                }}
                disableRipple
              />
            }
            label={<span>개인 정보 수집 및 이용에 동의합니다.</span>}
            sx={{
              my: isMobile ? -1 : 0,
              '& .MuiSvgIcon-root': {
                color: '#03CB5B', // Sets the color of the checkbox icon
              },
              // '& .MuiFormControlLabel-label': {
              //   // Targets the label part of the FormControlLabel
              //   fontSize: isMobile ? '7px' : '1rem', // Adjust the font size as needed
              //   letterSpacing: -0.8,
              //   marginLeft: '-8px',
              // },
            }}
          />
          <Typography
            component={'span'}
            sx={{
              textDecoration: 'underline',
              color: '#03CB5B',
              cursor: 'pointer',
            }}
            onClick={handleOpenDialog}
          >
            자세히 보기
          </Typography>
        </Stack>

        <Typography fontWeight={'bold'} fontSize={'1.2rem'} color={'#03CB5B'}>
          상담신청시 설치비 55만원 전액 무상지원!
        </Typography>

        <Button
          onClick={handleSubmit}
          fullWidth
          width="100%"
          sx={{
            color: color.white,
            fontSize: '1.2rem',
            height: 70,
            bgcolor: '#03CB5B',
            cursor: 'pointer',
            animation: 'pulse 1s infinite',
            '@keyframes pulse': {
              '0%, 50%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(0.95)' },
            },
            '&:hover': {
              bgcolor: '#029E43', // 조금 더 진한 초록색으로 변경
              transition: 'background-color 0.3s', // 배경색 전환 효과 추가
            },
          }}
        >
          상담 신청
        </Button>

        <Typography
          sx={{
            pt: 2,
            // bgcolor: '#fff',
            // width: '50%',
            // height: '10%',
            textAlign: 'center',

            // bgcolor: '#03CB5B',
            color: '#111',
            // borderRadius: '40px',
            letterSpacing: '-0.8px',
            fontWeight: 'bold',
            fontSize: fontSize3, // 동적으로 설정된 폰트 크기
            whiteSpace: 'pre-line',
            lineHeight: '1.2',
          }}
        >
          상담 및 가입문의 대표번호
          <br />
          <span style={{ color: '#03CB5B', fontSize: fontSize2 }}>
            1555-1750{' '}
          </span>
        </Typography>

        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">개인정보취급방침</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="dialog-description"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {`'KT비즈솔루션'은 (이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
ο 본 방침은 : 2018 년 01 월 01 일 부터 시행됩니다.
■ 수집하는 개인정보 항목
회사는 상담 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
[온라인 문의]
- 수집항목
1. 필수항목 : 이름, 연락처, 이메일, 문의사항 등 2. 자동수집항목: 서비스 접속 로그, 접속 IP 정보
■ 개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 문의/답변
■ 개인정보의 보유 및 이용기간
원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 보존 항목 및 결제기록 보존 근거
- 계약 또는 청약철회 등에 관한 기록 보존 기간 : 3년
- 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)
- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)
■ 개인정보의 파기절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
- 파기절차
회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
- 파기방법
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
■ 개인정보 제공
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다. 이용자들이 사전에 동의한 경우 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
■ 이용자 및 법정대리인의 권리와 그 행사방법
이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자들의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다. 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 동의를 철회할 권리를 가집니다.
■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
회사는 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
- 쿠키 등 사용 목적
회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
- 쿠키 설정 거부 방법
쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
- 설정방법
예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
■ 개인정보에 관한 민원서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

고객서비스 담당 부서

부서명 : 관리팀
전화번호 : 1555-1750

개인정보관리 책임자

성명 : 김도훈
전화번호 : 1555-1750

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

개인분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)
개인정보침해신고센터 : 118, (privacy.kisa.or.kr)
대검찰청 사이버범죄수사단 : 1301, cid@spo.go.kr (www.spo.go.kr)
경찰청 사이버안전국 : 182, (cyberbureau.police.go.kr)`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>닫기</Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
};

export default Section02Form;
