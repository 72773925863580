import React, { useRef } from 'react';
import { sectionImages } from '../images';

import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';
import { Box, Fade } from '@mui/material';

const Problem02 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  return (
    <Fade in={isVisible} timeout={1000}>
      <Box ref={sectionRef}>
        <CommonImage
          width='100%'
          sx={{ mt: -1 }}
          src={sectionImages.Problem2}
        />
      </Box>
    </Fade>
  );
};

export default Problem02;
