import { Stack, Typography } from '@mui/material';
import React from 'react';
import { color } from '../elements';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { common } from '@mui/material/colors';

const contentsArr = [
  {
    title: '01. ',
    text: '밀폐공간 작업환경의 환경을 측정하는 것이 의무인가요?',
    contents: (isMobile) => (
      <>
        의무가 맞습니다. 산업안전보건기준에 관한 규칙 제619조 제2항에 의해
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          {' '}
          밀폐공간 작업 전 밀폐공간의 유해가스를 측정
        </Typography>
        하지 않으면 사업주 및 관리자는 작업허가를 내지 못하게 되어 있습니다.
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          작업 중에도 2시간에 1번 유해가스 농도를 측정
        </Typography>
        하여 기록 하게 되어 있습니다.
      </>
    ),
  },
  {
    title: '02. ',
    text: '우리회사도 중대재해 처벌법 대상인가요?',
    contents: (isMobile) => (
      <>
        2022년 1월부터 50인 이상 사업장을 대상으로 하고 있으며,{' '}
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          2024년 1월부터는 50인 이하도 대상{' '}
        </Typography>
        이 되므로 대부분의 회사는 처벌법의 대상이 됩니다.
      </>
    ),
  },
  {
    title: '03. ',
    text: '센서 설치시 검.교정을 한국인정기구에 꼭 받아야하나요?',
    contents: (isMobile) => (
      <>
        센서 검교정은 한국인정기구에 꼭 받을 필요는 없습니다.{' '}
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          (고용노동 부 안전가이드 Q&A사례 7번 답변 참고){' '}
        </Typography>
        제조사의 검.교정 인증만 으로도 문제가 없습니다.
      </>
    ),
  },
  {
    title: '04. ',
    text: '우리회사는 공장이 아니고 대부분 일반 사무실인데 중대재해 처벌법 대상이 되나요?',
    contents: (isMobile) => (
      <>
        네 됩니다. 대부분의 건물에는 사무실 이외에 위험한 밀폐공간이 존재합니다.
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          보일러실,소화 약제실, 하수처리시설, 전기 배전시설 등 법적으로 관리해야
          할 공간이 존재
        </Typography>
        하므로 미리 점검해야 합니다. 밀폐공간 안에서는 CO, CO2, H2S, O2의 농도를
        기준치 이하로 관리해야 하는 의무가 있습니다.
      </>
    ),
  },
  {
    title: '05. ',
    text: '만약 사고가 발생 하면은 누가 처벌을 받게 되나요?',
    contents: (isMobile) => (
      <>
        <Typography fontSize={isMobile ? '1rem' : '1.2rem'}>
          1명 이상의 사망자, 2명 이상의 부상자가 발생하면 사업주, 대표이사 및
          안전담당자는 형사처벌을 면하기 어렵습니다. 배상 책임 또한 손해
          추적액의 5배 내에서 결정되므로 위험지역에 대한 대비가 필요합니다.
        </Typography>
      </>
    ),
  },
  {
    title: '06. ',
    text: '어떤 처벌을 받게 되나요?',
    contents: (isMobile) => (
      <>
        담당자는{' '}
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          1년 이상의 징역 또는 10억원 이하의 벌금
        </Typography>
        에 처해집니다. 또한{' '}
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          회사에는 50억원 이하의 벌금이 부과
        </Typography>{' '}
        됩니다. (제7조 중대산업재해 양벌 규정)
      </>
    ),
  },
  {
    title: '07. ',
    text: '법적 배상액 규모는 얼마나 되나요?',
    contents: (isMobile) => (
      <>
        회사는 피해를 입은 사람(부상자 혹은 사망 유가족)에게 피해액의{' '}
        <Typography
          component={'span'}
          fontSize={isMobile ? '1rem' : '1.2rem'}
          sx={{ color: '#03CB5B' }}
        >
          최고 5배
        </Typography>
        까지 배상해야 합니다. (제15조 손해배상의 책임)
      </>
    ),
  },
];

const Faq = ({ isMobile }) => {
  return (
    <Stack backgroundColor={color.common_black} spacing={3} p={5}>
      <Typography
        textAlign={'center'}
        whiteSpace={'pre-line'}
        lineHeight={1.2}
        fontWeight={'bold'}
        fontFamily={'Pretendard'}
        letterSpacing={-0.8}
        my={3}
        py={4}
        mx={'auto'}
        color={color.white}
        fontSize={isMobile ? '1.5rem' : '2rem'}
      >
        {`FAQ`}
      </Typography>
      {contentsArr.map((contents) => {
        return (
          <Accordion
            sx={{
              bgcolor: color.common_black,
              color: color.white,

              borderTop: '1px solid white', // 위 보더를 흰색으로 지정
              borderBottom: '1px solid white', // 아래 보더를 흰색으로 지정
              borderLeft: 'none', // 좌 보더 제거
              borderRight: 'none', // 우 보더 제거
              py: 3,
            }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDownwardIcon
                  sx={{
                    color: common.white,
                  }}
                />
              }
              sx={{ bgcolor: color.common_black }}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography
                fontSize={isMobile ? '1rem' : '1.2rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
              >
                <Typography
                  component={'span'}
                  fontSize={isMobile ? '1rem' : '1.2rem'}
                  whiteSpace={'pre-line'}
                  lineHeight={1.2}
                  fontWeight={'bold'}
                  fontFamily={'Pretendard'}
                  letterSpacing={-0.8}
                  my={2}
                >
                  {contents.title}
                </Typography>
                {contents.text}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_black }}>
              <Typography
                fontSize={isMobile ? '1rem' : '1.2rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {contents.contents(isMobile)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
};

export default Faq;
